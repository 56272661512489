import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      textTransform: 'none',
      padding: '8px 12px',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
      borderRadius: 8,
      letterSpacing: '0.16px',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow:
        '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
      '&:hover': {
        boxShadow:
          '0px 0px 1px rgba(82, 82, 82, 0.1), 0px 4px 8px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
      },
      '&:focus': {
        boxShadow:
          '0px 0px 1px rgba(82, 82, 82, 0.1), 0px 3px 4px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
      },
      '&:active': {
        boxShadow:
          '0px 0px 4px rgba(82, 82, 82, 0.1), 0px 2px 6px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
      },
      '& svg': {
        background: 'transparent',
        color: 'inherit',
        fontSize: 20,
      },
    },
    disabled: {
      color: `${palette.cncUI.primary.gray['+7']} !important`,
      border: `1px solid ${palette.cncUI.primary.gray['+6']}`,
      backgroundColor: palette.cncUI.button.default.disabled,
      boxShadow: '0px 0px 1px rgba(82, 82, 82, 0.4)',
      '& .MuiCircularProgress-root': {
        color: `${palette.cncUI.primary.gray['+7']} !important`,
      },
    },
    plain: {
      height: 40,
      padding: '8px 12px',
      borderRadius: 8,
      border: `1px solid ${palette.dashboard.gray300}`,
      background: palette.dashboard.white,
      color: palette.dashboard.gray500,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      '&:hover, &:focus, &:active': {
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      },
    },
    primary: {
      color: palette.cncUI.default['+10'],
      backgroundColor: palette.dashboard.blue600,
      border: `1px solid ${palette.dashboard.blue600}`,
      '& .MuiCircularProgress-root': {
        color: palette.cncUI.default['+10'],
      },
      '&:hover': {
        backgroundColor: palette.cncUI.button.default.hover,
        border: `1px solid ${palette.cncUI.button.default.hover}`,
      },
      '&:focus': {
        backgroundColor: palette.cncUI.button.default.focus,
        border: `1px solid ${palette.cncUI.button.default.focus}`,
      },
      '&:active': {
        backgroundColor: palette.cncUI.button.default.active,
        border: `1px solid ${palette.cncUI.button.default.active}`,
      },
    },
    secondary: {
      backgroundColor: palette.cncUI.default['+10'],
      color: palette.dashboard.blue700,
      border: `1px solid ${palette.dashboard.blue300}`,
      '& .MuiCircularProgress-root': {
        color: palette.cncUI.button.default.primary,
      },
      '&:hover': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.default.hover,
      },
      '&:focus': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.default.focus,
      },
      '&:active': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.default.active,
      },
    },
    tertiary: {
      color: palette.dashboard.blue700,
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      boxShadow: 'none',
      '& .MuiCircularProgress-root': {
        color: palette.dashboard.blue700,
      },
      '&:hover': {
        color: palette.cncUI.button.default.hover,
        border: `1px solid ${palette.cncUI.button.default.hover}`,
      },
      '&:focus': {
        color: palette.cncUI.button.default.focus,
        border: `1px solid ${palette.cncUI.button.default.focus}`,
      },
      '&:active': {
        color: palette.cncUI.button.default.active,
        border: `1px solid ${palette.cncUI.button.default.active}`,
      },
    },
    danger: {
      color: palette.cncUI.primary.gray['+9'],
      backgroundColor: palette.cncUI.button.red.primary,
      border: `1px solid ${palette.cncUI.button.red.primary}`,
      '&:hover': {
        backgroundColor: palette.cncUI.button.red.hover,
        border: `1px solid ${palette.cncUI.button.red.hover}`,
      },
      '&:focus': {
        backgroundColor: palette.cncUI.button.red.focus,
        border: `1px solid ${palette.cncUI.button.red.focus}`,
      },
      '&:active': {
        backgroundColor: palette.cncUI.button.red.active,
        border: `1px solid ${palette.cncUI.button.red.active}`,
      },
    },
    white: {
      color: palette.cncUI.default['+10'],
      '&:hover': {
        color: palette.cncUI.default['+10'],
      },
      '&:focus': {
        color: palette.cncUI.default['+10'],
      },
      '&:active': {
        color: palette.cncUI.default['+10'],
      },
    },
    secondaryDanger: {
      backgroundColor: palette.cncUI.default['+10'],
      color: palette.cncUI.button.red.primary,
      border: `1px solid ${palette.cncUI.button.red.primary}`,
      '& .MuiCircularProgress-root': {
        color: palette.cncUI.button.red.primary,
      },
      '&:hover': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.red.hover,
      },
      '&:focus': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.red.focus,
      },
      '&:active': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.red.active,
      },
    },
    secondaryDisabled: {
      border: `1px solid ${palette.cncUI.primary.gray['+6']} !important`,
    },
    tertiaryDanger: {
      backgroundColor: palette.cncUI.default['+10'],
      border: '1px solid transparent',
      color: palette.cncUI.button.red.primary,
      '& .MuiCircularProgress-root': {
        color: palette.cncUI.button.red.primary,
      },
      '&:hover': {
        border: `1px solid ${palette.cncUI.button.red.primary}`,
        color: palette.cncUI.button.red.primary,
        backgroundColor: palette.cncUI.default['+10'],
      },
      '&:focus': {
        border: `1px solid ${palette.cncUI.button.red.primary}`,
        color: palette.cncUI.button.red.primary,
        backgroundColor: palette.cncUI.default['+10'],
      },
      '&:active': {
        color: palette.cncUI.default['+10'],
        backgroundColor: palette.cncUI.button.red.active,
      },
    },
    tertiaryDisabled: {
      border: 'none !important',
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
    },
    dangerDisabled: {
      backgroundColor: palette.cncUI.button.red.disabled,
      border: `1px solid ${palette.cncUI.button.red.disabled}`,
    },
    circle: {
      borderRadius: '50%',
      minWidth: 40,
      height: 40,
      padding: 0,
    },
    short: {
      fontSize: 12,
      lineHeight: '16px',
      padding: '3.5px 8px',
      minWidth: 36,
      minHeight: 22,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
      '& .MuiButton-startIcon': {
        marginRight: 4,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    circleShort: {
      minWidth: 25,
      height: 25,
      padding: 0,
    },
    loading: {
      pointerEvents: 'none',
      color: 'transparent !important',
    },
    icon: {
      maxWidth: 36,
      minWidth: 36,
      width: 36,
      height: 36,
      padding: 0,
    },
    iconShort: {
      minWidth: 25,
      width: 25,
      height: 25,
      padding: 0,
    },
    notify: {
      display: 'block',
      width: 10,
      height: 10,
      background: palette.cncUI.alert.red.primary,
      position: 'absolute',
      top: -4,
      right: -4,
      borderRadius: '50%',
      border: `2px solid ${palette.cncUI.default['+10']}`,
    },
    notifyShort: {
      width: 6,
      height: 6,
      borderWidth: 1,
      top: -2,
      right: -2,
    },
    spinner: {
      position: 'absolute',
      zIndex: 1,
    },
  }),
  { name: 'CncUI-Button' },
);

const Button = props => {
  const classes = useStyles();
  const {
    children,
    className,
    width,
    plain = false,
    secondary = false,
    tertiary = false,
    danger = false,
    disabled = false,
    circle = false,
    short = false,
    loading = false,
    white = false,
    icon = false,
    notify = false,
    ...other
  } = props;

  const style = props.style || {};

  if (width) {
    style.width = width;
  }

  const options = {
    style,
    disabled,
    variant: 'standard',
    className: clsx(
      'cncui-button',
      classes.root,
      !plain && !secondary && !tertiary && !disabled && classes.primary,
      plain && classes.plain,
      secondary && classes.secondary,
      tertiary && classes.tertiary,
      danger && classes.danger,
      disabled && classes.disabled,
      secondary && danger && classes.secondaryDanger,
      secondary && disabled && classes.secondaryDisabled,
      tertiary && danger && classes.tertiaryDanger,
      tertiary && disabled && classes.tertiaryDisabled,
      danger && disabled && classes.dangerDisabled,
      white && classes.white,
      circle && classes.circle,
      short && classes.short,
      icon && classes.icon,
      icon && short && classes.iconShort,
      circle && short && classes.circleShort,
      loading && classes.loading,
      className,
    ),
  };

  return (
    <MuiButton {...options} {...other}>
      {circle && notify && (
        <span className={clsx(classes.notify, short && classes.notifyShort)} />
      )}
      {children}
      {loading && (
        <CircularProgress size={short ? 16 : 18} className={classes.spinner} />
      )}
    </MuiButton>
  );
};

export default Button;
